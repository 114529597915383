import { Badge, Box, Button, Menu, styled } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import useWindowSize from "~/hooks/useWindowSize";
import React, { FC, useEffect, useState } from "react";
import { useRootLoader } from "~/utils/use-root-loader";
import { StyledNavLink, Wrapper } from "./styles";
import { useLocation } from "@remix-run/react";
import Rive from "@rive-app/react-canvas";
import BazaarImage from "../BazaarImage";
import AccountPopover from "../AccountPopover";

export const StyledBox = styled(Box)(({ theme }) => ({
  width: 24,
  height: 24,
}));

export const StyledP = styled(Box)(({ theme }) => ({
  fontSize: 12,
  textAlign: "center",
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  flexWrap: "wrap",
}));

const MobileNavigationBar: FC = () => {
  const [openBranchWindow, setOpenBranchWindow] = useState(false);
  const width = useWindowSize();
  const { cart, isCustomerLoggedIn } = useRootLoader();

  const [selectedBranch, setSelectedBranch] = useState("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSelectClick = () => {
    setOpenBranchWindow(true);
    open ? setAnchorEl(null) : null;
  };
  return (
    <Wrapper sx={{ zIndex: "1000000" }}>
      <StyledNavLink to={"/"}>
        <StyledBox>
          <img src={"/assets/images/icons/home-page.svg"} alt="homepage" />
        </StyledBox>
        <StyledP>Нүүр</StyledP>
      </StyledNavLink>

      <StyledNavLink to={"/flash-delivery"}>
        <StyledBox>
          <BazaarImage src="/assets/image.png" width="24px" height="24px" />
        </StyledBox>
        <StyledP sx={{ fontWeight: "400" }}>Шуурхай</StyledP>
      </StyledNavLink>
      {/* <Menu
        keepMounted={false}
        disablePortal={true}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            width: 278,
            p: "14px",
            textAlign: "center",
            fontSize: "12px",
            height: "fit-content",
          },
          style: {
            transform: "translateY(-64px)",
          },
        }}
        id="basic-menu"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        <div key={1}>
          {selectedBranch ? (
            <Box>
              Танд бид <strong>{selectedBranch}</strong> салбарын барааг харуулж
              байна. Өөрт ойр салбарыг сонгосноор хэрэгцээт бараа бүтээгдэхүүнээ
              4 цагийн дотор хүргүүлээд аваарай.
            </Box>
          ) : (
            <Box>
              <strong>Өөрт ойр салбар сонгох хүргэлт</strong>
              <br />
              <br />
              Уг хүргэлтийг сонгосноор <br />
              <strong>4 цагийн</strong> дотор хэрэгцээт бараа бүтээгдэхүүнээ
              хүргүүлэн авах боломжтой.
              <br />
            </Box>
          )}
           <BazaarImage
            src="/assets/images/illustrations/shuurhai.png"
            alt="shuurhai"
            width="100%"
          /> 

          <Button
            sx={{
              backgroundColor: "#2D68C4",
              width: 165,
              height: 23,
              color: "white",
              borderRadius: "8px",
              mt: "12px",
              "&:hover": { backgroundColor: "#24539c", color: "white" },
            }}
            onClick={handleSelectClick}
          >
            <LocationOnIcon />
            Салбар сонгох
          </Button>
        </div>
      </Menu> */}
      {/* <SelectBoxPhone setAnchorEl={setAnchorEl} /> */}
      <StyledNavLink to={"/t/27318"}>
        <StyledBox>
          <Rive src="/assets/images/rive/discount_animated_color.riv" />
        </StyledBox>
        <StyledP>Хямдралтай</StyledP>
      </StyledNavLink>
      <StyledNavLink to={"/cart"}>
        <Badge badgeContent={cart.itemsCount} color="primary">
          <StyledBox>
            {cart.itemsCount == 0 ? (
              <img
                src={"/assets/images/icons/cart-empty.svg"}
                alt="cartempty"
              />
            ) : (
              <img
                src={"/assets/images/icons/cart-filled.svg"}
                alt="cartfilled"
              />
            )}
          </StyledBox>
        </Badge>
        <StyledP>Сагс</StyledP>
      </StyledNavLink>
      <StyledNavLink to={isCustomerLoggedIn ? "/c/profile" : "/login"}>
        {isCustomerLoggedIn ? (
          <AccountPopover isMobile={true} />
        ) : (
          <StyledBox>
            <Box width={"24px"} height="24px">
              <img
                src="/assets/images/icons/Profile.svg"
                alt="profile"
                width="100%"
                height="100%"
              />
            </Box>
          </StyledBox>
        )}

        <StyledP>Хэрэглэгч</StyledP>
      </StyledNavLink>
    </Wrapper>
  );
};

export default MobileNavigationBar;
